<template>
  <app-modal title="User edition" @submit="onSubmit">
    <div class="grid grid-cols-2 gap-x-4">
      <app-form-input-text
        v-model="form.email"
        class="col-span-2"
        label="Email"
        required
        type="email"
      />

      <app-form-input-text
        v-model="form.first_name"
        label="First name"
        required
      />

      <app-form-input-text
        v-model="form.last_name"
        label="Last name"
        required
      />

      <app-form-input-text
        v-model="form.company_name"
        class="col-span-2"
        label="Company"
        required
      />

      <app-form-input-text
        v-model="form.job_title"
        class="col-span-2"
        label="Job title"
        required
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import type { UserView } from "@pollen/core/model/user.model";

import type { UserUpdateRequest } from "~~/model/user.model";

const properties = defineProps<{
  user: UserView;
}>();

const form = reactive<UserUpdateRequest>({
  email: properties.user.email,
  first_name: properties.user.first_name,
  last_name: properties.user.last_name,
  company_name: properties.user.company_name ?? "",
  job_title: properties.user.job_title ?? "",
});

function onSubmit() {
  return useModal("userEditionModal").close({ confirmed: true, payload: form });
}
</script>
